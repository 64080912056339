const routes = [
  {
    path: ["/", "/home", "/hem"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/pictures", "/bilder"],
    exact: true,
    component: "Picture",
  },
  {
    path: ["/thorbjorn", "/thorbjorn"],
    exact: true,
    component: "Thorbjorn",
  },
  {
    path: ["/thorbjorn/tiny-house", "/thorbjorn/tiny-house"],
    exact: true,
    component: "TinyHouse",
  },
  {
    path: ["/thorbjorn/tiny-house/bilder", "/thorbjorn/tiny-house/bilder"],
    exact: true,
    component: "Picture",
  },
  {
    path: ["/thorbjorn/brf", "/thorbjorn/brf"],
    exact: true,
    component: "ThorbjornBRF",
  },
  {
    path: ["/thorbjorn/kontakt", "/thorbjorn/kontakt"],
    exact: true,
    component: "ThorbjornKontakt",
  },
  {
    path: ["/thorbjorn/about", "/thorbjorn/om-oss"],
    exact: true,
    component: "ThorbjornAbout",
  },
  {
    path: ["/thorbjorn/bestallar-stod"],
    exact: true,
    component: "ThorbjornBestallarStod",
  },
  {
    path: ["/petra", "/petra"],
    exact: true,
    component: "PetraKontakt",
  },
];

export default routes;
